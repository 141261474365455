import { Link } from "gatsby";
import React from "react";
import ProjectPreview from "./project-preview";

import * as styles from "./project-preview-grid.module.css";

function ProjectPreviewGrid(props) {
  return (
    <div className={styles.root}>
      <ul className={styles.grid}>
        {props.nodes &&
          props.nodes.map(node => (
            <li key={node.id} className={node.mainImageSize}>
              <ProjectPreview {...node} />
            </li>
          ))}
      </ul>
    </div>
  );
}

ProjectPreviewGrid.defaultProps = {
  nodes: [],
  browseMoreHref: ""
};

export default ProjectPreviewGrid;
