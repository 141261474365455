import { Link } from "gatsby";
import React, { useEffect } from 'react';
import { cn, buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BlockContent from "./block-content";

import * as styles from "./project-preview.module.css";
import { responsiveTitle } from "./typography.module.css";

// import { GatsbyImage } from "gatsby-plugin-image"

/* 

        <div className={styles.backgroundImage} style={{
          backgroundImage: `url(${imageUrlFor(buildImageObj(props.mainImage))
            .url()})`
        }}>

*/

function contentLoaded() {
  // console.log('io');
  const lazyBackgrounds = [].slice.call(document.querySelectorAll(".lazybg"));

  if ("IntersectionObserver" in window) {
    let lazyBackgroundObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
          lazyBackgroundObserver.unobserve(entry.target);
        }
      });
    });

    lazyBackgrounds.forEach(function (lazyBackground) {
      lazyBackgroundObserver.observe(lazyBackground);
    });
  }
}




function ProjectPreview(props) {


  useEffect(() => {
    // console.log("using effect");
    contentLoaded();
  }, []);



  return (
    <Link className={styles.root} to={`/project/${props.slug.current}`}>
      <div className={styles.leadMediaThumb}>
        {props.mainImage && props.mainImage.asset && (
          <div className={styles.backgroundImage + ` lazybg`} style={{
            backgroundImage: `url(${imageUrlFor(buildImageObj(props.mainImage))
              .width(1000)
              .url()
              })`
          }}>
          </div>
        )}
      </div>
      <h3 className={styles.grid__title}>{props.title} 〔{props.lieu}〕</h3>

    </Link>
  );
}
export default ProjectPreview;
